<template>
  <div id="admin-sessions">
    <!-- App Bar -->
    <v-app-bar app color="white" light flat>
      <v-app-bar-nav-icon
        v-if="!sidebarMenu"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
    </v-app-bar>
    <!-- Sidebar -->
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      :permanent="sidebarMenu"
      :mini-variant.sync="mini"
    >
      <template #prepend>
        <v-list-item two-line>
          <v-list-item-action>
            <v-icon v-if="sidebarMenu" @click.stop="sidebarMenu = !sidebarMenu">
              mdi-chevron-left
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <router-link to="/" class="black--text text-decoration-none">
              <img
                width="50%"
                height="70%"
                src="@/assets/images/logo/with-black.png"
                class="mt-3 ml-3"
              />
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
          active-class="yellow"
          class="opacity-1"
        >
          <v-list-item-icon>
            <v-icon color="light-grey">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="light-grey">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template #append>
        <v-list>
          <v-list-item
            active-class="yellow"
            class="opacity-1"
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon color="light-grey">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="light-grey">
                {{ $t('logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- Main Container -->
    <v-container class="py-0 dashboard-container" fluid>
      <v-row class="fill-height">
        <v-col
          xl="3"
          md="5"
          sm="12"
          xs="12"
          class="pa-6 grey session-card-section"
        >
          <v-card flat tile color="grey">
            <v-card-title class="text-h5 pb-0 px-1">
              {{ $t('upc_session') }}
              <v-chip
                class="white--text text--opacity-25 text-body-2 ml-4"
                color="#ADADAD"
                :ripple="false"
                small
              >
                {{ totalBookings }}
              </v-chip>
            </v-card-title>
            <template v-if="loading">
              <v-card
                color="white"
                elevation="24"
                height="40"
                class="pa-3 mt-4"
              >
                <v-row no-gutters>
                  <v-skeleton-loader width="130" type="text" />
                  <v-skeleton-loader class="ml-auto" width="130" type="text" />
                </v-row>
              </v-card>
              <v-card v-for="n in 10" :key="n" flat class="my-3" height="100">
                <v-skeleton-loader type="list-item-avatar-two-line" />
                <v-row no-gutters>
                  <v-skeleton-loader class="ml-4" width="130" type="text" />
                  <v-skeleton-loader
                    class="ml-auto mr-4"
                    width="130"
                    type="text"
                  />
                </v-row>
              </v-card>
            </template>
            <template v-if="sessions && sessions.length && !loading">
              <div
                v-for="(groupByDateSessions, index) in sessions"
                :key="index"
                class="mt-6 mb-12"
              >
                <v-card color="white" elevation="24">
                  <v-card-title>
                    <span class="text-subtitle-1"
                      >{{
                        groupByDateSessions.date
                          | sessionGRPDate('dddd, D MMMM YYYY')
                      }}
                      <v-btn
                        class="ml-2"
                        height="10"
                        width="10"
                        text
                        icon
                        :ripple="false"
                        @click="
                          groupByDateSessions.isShow = !groupByDateSessions.isShow
                        "
                      >
                        <v-icon color="black" left>{{
                          groupByDateSessions.isShow
                            ? 'mdi-chevron-up'
                            : 'mdi-chevron-down'
                        }}</v-icon>
                      </v-btn>
                    </span>
                    <span class="ml-auto text-subtitle-2 text--opacity-75"
                      >{{ groupByDateSessions.sessions.length }} sessions</span
                    >
                  </v-card-title>
                </v-card>
                <template v-if="groupByDateSessions.isShow">
                  <div
                    v-for="session in groupByDateSessions.sessions"
                    :key="session.index"
                  >
                    <v-hover
                      v-slot="{ hover }"
                      close-delay="100"
                      open-delay="100"
                    >
                      <v-card
                        :key="session.index"
                        active-class="primary session-card-box-shadow"
                        :class="{
                          'primary session-card-box-shadow':
                            hover || session.id == selectedSession.id,
                        }"
                        class="my-3"
                        flat
                        @click="onSelectSession(session)"
                      >
                        <v-list-item two-line>
                          <v-list-item-avatar tile class="rounded" size="48">
                            <v-img
                              :src="
                                session.host.profileImg
                                  ? session.host.profileImg
                                  : $defaultImg
                              "
                            />
                          </v-list-item-avatar>
                          <v-list-item-content
                            class="text-left mt-n3"
                            :class="whiteText(session.id, hover)"
                          >
                            <v-list-item-title class="mb-1">
                              <span class="text-subtitle-1">{{
                                fullName(session.host)
                              }}</span>
                              <span
                                class="text-subtitle-2 pl-1 text--opacity-75"
                                :class="whiteText(session.id, hover)"
                              >
                                {{ $t('with') }}
                              </span>
                              <span class="text-subtitle-1 pl-1">{{
                                fullName(session.guest)
                              }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="text-subtitle-2 text--opacity-75"
                              :class="whiteText(session.id, hover)"
                            >
                              {{ session.sessionType.title }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title
                            class="text-left text-subtitle-2 text--opacity-75"
                            :class="whiteText(session.id, hover)"
                          >
                            {{ session.sessionType.length }} mins./${{
                              session.sessionType.charge | toFixed(2)
                            }}
                          </v-list-item-title>
                          <v-list-item-title
                            class="text-right text-subtitle-2 text--opacity-75"
                            :class="whiteText(session.id, hover)"
                          >
                            {{ $t('session_at') }}
                            {{ session.startTime | formatDate('hh:mm a') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <v-card-text class="text-h6 pa-0 pt-5">
                {{ $t('no_upcmng_session') }}
              </v-card-text>
            </template>
          </v-card>
        </v-col>
        <v-col
          xl="9"
          md="7"
          sm="12"
          xs="12"
          class="grey_regular pa-6 session-card-section"
        >
          <v-card
            flat
            tile
            color="grey_regular"
            height="fill-height"
            class="selected-session-card"
          >
            <template v-if="loading">
              <v-skeleton-loader type="list-item-avatar-two-line" width="300" />
              <v-card
                color="white"
                flat
                height="40"
                class="pa-3 ma-4 text-center"
              >
                <v-row no-gutters justify="center">
                  <v-skeleton-loader width="250" type="text" />
                </v-row>
              </v-card>
              <v-card color="white" flat class="rounded mx-4 my-4 pa-6">
                <v-card-text class="text-subtitle-2 text--opacity-75 pb-0">
                  {{ $t('change_date') }}
                </v-card-text>
                <v-card-text>
                  <v-skeleton-loader width="300" type="text" />
                </v-card-text>
                <v-card-text class="text-subtitle-2 text--opacity-75 pb-0">
                  {{ $t('change_time') }}
                </v-card-text>
                <v-card-text>
                  <v-row no-gutters>
                    <v-skeleton-loader
                      v-for="n in 18"
                      :key="n"
                      class="ma-3"
                      type="button"
                    />
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
            <template v-else-if="selectedSession && !loading">
              <v-list-item two-line>
                <v-list-item-avatar tile class="rounded" size="48">
                  <v-img
                    :src="
                      selectedSession.host.profileImg
                        ? selectedSession.host.profileImg
                        : $defaultImg
                    "
                  />
                </v-list-item-avatar>
                <v-list-item-content class="text-left mt-n3">
                  <v-list-item-title class="mb-1">
                    <span class="text-subtitle-1">{{
                      fullName(selectedSession.host)
                    }}</span>
                    <span class="text-subtitle-2 text--opacity-75 pl-1"
                      >with</span
                    >
                    <span class="text-subtitle-1 pl-1">{{
                      fullName(selectedSession.guest)
                    }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="text-subtitle-2 text--opacity-75"
                  >
                    {{ selectedSession.sessionType.title }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card color="white" flat class="rounded mx-4">
                <v-card-text class="black--text text-center">
                  <span class="text-subtitle-2 text--opacity-75"
                    >Session starts</span
                  >
                  <span class="text-subtitle-1 pl-1">{{
                    selectedSession.startTime
                      | formatDate('dddd MMMM DD, YYYY, hh:mm a')
                  }}</span>
                </v-card-text>
              </v-card>
              <v-card color="white" flat class="rounded mx-4 my-4 pa-6">
                <v-card-text class="text-subtitle-2 text--opacity-75 pb-0">
                  {{ $t('change_date') }}
                </v-card-text>
                <v-card-text class="pt-0">
                  <v-row no-gutters>
                    <v-flex xs12 md5>
                      <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormattedMomentjs"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="selectedDate"
                          :min="currentDate"
                          @change=";(dateMenu = false), getAppointments()"
                        />
                      </v-menu>
                    </v-flex>
                  </v-row>
                </v-card-text>
                <v-card-text class="text-subtitle-2 text--opacity-75 pb-0">
                  {{ $t('change_time') }}
                </v-card-text>
                <v-card-text
                  v-if="!timeSlotsLoader && timeSlots.length"
                  class="black--text text-subtitle-2"
                >
                  <TimeBlock
                    :appointments="timeSlots"
                    :start-time.sync="updateSession.startTime"
                    :end-time.sync="updateSession.endTime"
                    :show-session-btn.sync="updateSession.showBtn"
                  />
                </v-card-text>
                <v-card-text
                  v-else-if="timeSlots.length === 0 && !timeSlotsLoader"
                  class="text-center"
                >
                  {{ $t('no_timeslot_found') }}
                </v-card-text>
                <v-card-text v-else class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="text-center"
                  />
                </v-card-text>
                <v-card-actions
                  v-if="updateSession.showBtn"
                  class="justify-center"
                >
                  <v-btn
                    width="350"
                    large
                    :color="updateSession.color"
                    :ripple="false"
                    :loading="updateSession.loading"
                    @click="updateSessionTime()"
                  >
                    {{ updateSession.text }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'

// graphql
import {
  GET_ALL_UPCOMING_SESSIONS,
  UPDATE_SESSION_START_TIME,
} from '@/graphql/admin'
import { GET_TALENT_AVAILABILITY } from '@/graphql/talent'

// utils
import i18n from '@/utils/i18n.js'
import { delay } from '@/utils/helpers'

// component
import TimeBlock from '@/components/booking/TimeBlock.vue'

export default {
  components: {
    TimeBlock,
  },
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      items: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          url: '/admin/dashboard',
        },
        { title: 'Sessions', icon: 'mdi-clock', url: '/admin/sessions' },
        { title: 'Hosts', icon: 'mdi-calendar-blank', url: '/admin/hosts' },
        {
          title: 'Users',
          icon: 'mdi-help-circle-outline',
          url: '/admin/users',
        },
      ],
      currentDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      selectedDate: null,
      dateMenu: false,
      loading: false,

      updateSession: {
        startTime: null,
        endTime: null,
        loading: false,
        showBtn: false,
        color: 'white',
        text: i18n.t('update_session'),
      },

      totalBookings: 0,
      first: 0,
      skip: 0,
      totalSessions: [],
      sessions: [],
      selectedSession: null,
      timeSlots: [],
      timeSlotsLoader: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'jwt']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
    whiteText() {
      return (index, hover) =>
        index == this.selectedSession.index || hover ? 'white--text' : null
    },
    computedDateFormattedMomentjs() {
      return this.selectedDate
        ? this.$moment(this.selectedDate).format('dddd, MMMM Do YYYY')
        : ''
    },
    fullName() {
      return user => `${user.firstName} ${user.lastName}`
    },
  },
  async beforeMount() {
    await this.getUpcomingSessions()
  },
  methods: {
    /**
     * On selected Session
     */
    async onSelectSession(session) {
      console.log(session)
      this.selectedSession = { ...session }
      this.selectedDate = this.$options.filters.formatDate(
        session.startTime,
        'YYYY-MM-DD'
      )
      await this.getAppointments()
    },

    /**
     * Update Session
     */
    async updateSessionTime() {
      this.updateSession.loading = true
      try {
        let updateDate = this.$options.filters.formatDate(
          this.selectedDate,
          'YYYY-MM-DD'
        )
        const {
          data: { updateSessionStartTime },
        } = await this.$apollo.mutate({
          mutation: UPDATE_SESSION_START_TIME,
          variables: {
            sessionData: {
              email: this.user.email,
              accesstoken: this.jwt,
              sessionid: this.selectedSession.id,
              date: updateDate,
              startTime: this.updateSession.startTime,
              endTime: this.updateSession.endTime,
              tzinfo: this.$defaultTZ,
            },
          },
        })

        if (updateSessionStartTime.success) {
          // Update session time on timeSlots
          this.timeSlots.map(timeSlot => {
            if (timeSlot.startTime === this.updateSession.startTime)
              timeSlot.booked = true
            else if (
              timeSlot.startTime ===
              this.selectedSession.startTime.split('T')[1]
            )
              timeSlot.booked = false
          })

          // Update original selected Session
          this.selectedSession.startTime = `${updateDate}T${this.updateSession.startTime}`
          this.selectedSession.endTime = `${updateDate}T${this.updateSession.endTime}`

          // Update session time on total Sessions
          this.totalSessions.map(session => {
            if (session.id === this.selectedSession.id) {
              session.startTime = `${updateDate}T${this.updateSession.startTime}`
              session.endTime = `${updateDate}T${this.updateSession.endTime}`
            }
          })
          // Sort session by time
          this.totalSessions = this.totalSessions.sort(
            (a, b) => new Date(a.startTime) - new Date(b.startTime)
          )

          // Group session again with new total sessions
          this.sessions = []
          this.totalSessions.forEach((session, index) => {
            const date = session.startTime.split('T')[0]
            const sessionIndex = this.sessions.findIndex(
              info => info.date === date
            )
            if (sessionIndex >= 0) {
              this.sessions[sessionIndex].sessions.push({
                ...session,
                index: index,
              })
            } else {
              this.sessions.push({
                date: date,
                isShow: true,
                sessions: [{ ...session, index: index }],
              })
            }
          })

          this.updateSession.loading = false
          this.updateSession.showBtn = true
          this.updateSession.color = 'primary'
          this.updateSession.text = i18n.t('session_updated')
          await delay(2000)
          this.updateSession.showBtn = false
          this.updateSession.color = 'white'
          this.updateSession.text = i18n.t('update_session')
        } else {
          this.$toasted.error(this.$t(updateSessionStartTime.error))
          this.updateSession.loading = false
        }
      } catch (e) {
        console.error(e) // eslint-disable-line
        this.updateSession.loading = false
      }
    },

    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },

    /**
     * Get All Upcoming Sessions
     */
    async getUpcomingSessions() {
      this.loading = true
      const {
        data: { allUpcomingSessions },
      } = await this.$apollo.query({
        query: GET_ALL_UPCOMING_SESSIONS,
        variables: {
          tokenInput: {
            email: this.user.email,
            accesstoken: this.jwt,
          },
          pagination: {
            first: this.first,
            skip: this.skip,
          },
          tzinfo: this.$defaultTZ,
        },
      })

      if (allUpcomingSessions.success) {
        this.totalBookings = allUpcomingSessions.totalBookings
        if (
          allUpcomingSessions.bookedList &&
          allUpcomingSessions.bookedList.length
        ) {
          this.totalSessions = allUpcomingSessions.bookedList
          this.sessions = []
          allUpcomingSessions.bookedList.forEach((session, index) => {
            const date = session.startTime.split('T')[0]
            const sessionIndex = this.sessions.findIndex(
              info => info.date === date
            )
            if (sessionIndex >= 0) {
              this.sessions[sessionIndex].sessions.push({
                ...session,
                index: index,
              })
            } else {
              this.sessions.push({
                date: date,
                isShow: true,
                sessions: [{ ...session, index: index }],
              })
            }
          })
          if (this.sessions.length) {
            this.selectedSession = this.sessions[0].sessions.find(
              session => session.index == 0
            )
            this.selectedDate = this.$options.filters.formatDate(
              this.selectedSession.startTime,
              'YYYY-MM-DD'
            )
            this.loading = false
            await this.getAppointments()
          }
        }
      } else {
        this.loading = false
      }
    },

    /**
     * Get Talent Appointments Based On Session and Date
     */
    async getAppointments() {
      this.timeSlotsLoader = true
      this.timeSlots = []
      try {
        const {
          data: { fetchAppointments },
        } = await this.$apollo.query({
          query: GET_TALENT_AVAILABILITY,
          fetchPolicy: 'no-cache',
          variables: {
            appointment: {
              date: this.$options.filters.formatDate(
                this.selectedDate,
                'YYYY-MM-DD'
              ),
              sessionid: this.selectedSession.sessionType.id,
              tzinfo: this.$defaultTZ,
            },
          },
        })
        if (fetchAppointments.success) {
          this.timeSlotsLoader = false
          this.timeSlots = fetchAppointments.appointmentList
        } else {
          this.timeSlotsLoader = false
        }
      } catch (error) {
        this.timeSlotsLoader = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#admin-sessions {
  min-height: calc(100vh - 65px);
}
.dashboard-container {
  margin-top: 9px;
}
.session-card-box-shadow {
  box-shadow: 0 24px 32px 0 $blue_spindle !important;
  transition: box-shadow 200ms ease, margin 200ms ease, color 200ms ease,
    background-color 200ms ease !important;
}
.session-card-section {
  height: calc(100vh - 65px);
  overflow-y: auto;
}
</style>

<style lang="scss">
.selected-session-card
  .v-skeleton-loader
  .v-skeleton-loader__list-item-avatar-two-line {
  background: none !important;
}
</style>
